export const RESET_STATE = 'RESET_STATE';
export const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
export const FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS =
  'FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS';
export const SET_COMPETITION = 'SET_COMPETITION';
export const FETCH_EVENT_BY_COMPETITION_SUCCESS =
  'FETCH_EVENT_BY_COMPETITION_SUCCESS';
export const SET_EXCH_EVENT = 'SET_EXCH_EVENT';
export const UPDATE_PREMIUM_MARKETS_WS = 'UPDATE_PREMIUM_MARKETS_WS';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_LOADING = 'SET_LOADING';
export const RESET_EVENTS = 'RESET_EVENTS';
export const UPDATE_SECONDARY_MARKETS = 'UPDATE_SECONDARY_MARKETS';
export const UPDATE_SECONDARY_MATCH_ODDS = 'UPDATE_SECONDARY_MATCH_ODDS';
export const DISABLE_EVENT_DATA = 'DISABLE_EVENT_DATA';
export const UPDATE_SCORECARD = 'UPDATE_SCORECARD';
export const UPDATE_BINARY_MARKETS = 'UPDATE_BINARY_MARKETS';
export const FETCH_SPORTS_BY_PROVIDER_SUCCESS =
  'FETCH_SPORTS_BY_PROVIDER_SUCCESS';
export const UPDATE_PREMIUM_MARKETS = 'UPDATE_PREMIUM_MARKETS';
export const UPDATE_FANCY_MARKETS = 'UPDATE_FANCY_MARKETS';
export const UPDATE_BOOKMAKER_MARKETS = 'UPDATE_BOOKMAKER_MARKETS';
export const FETCH_TOTAL_EVENT_LIST = 'FETCH_TOTAL_EVENT_LIST';
export const FETCH_SPORTS_LIVE_EVENT_LIST = 'FETCH_SPORTS_LIVE_EVENT_LIST';
export const FETCH_SPORTS_UPCOMING_EVENT_LIST =
  'FETCH_SPORTS_UPCOMING_EVENT_LIST';
export const UPDATE_TOPIC_URLS = 'UPDATE_TOPIC_URLS';
export const SUSPENDED_MARKETS = 'SUSPENDED_MARKETS';
export const COMMISSION_MARKETS = 'COMMISSION_MARKETS';
export const TRIGGER_FETCH_MARKETS = 'TRIGGER_FETCH_MARKETS';
export const TRIGGER_FETCH_ORDERS = 'TRIGGER_FETCH_ORDERS';
export const TRIGGER_BET_STATUS = 'TRIGGER_BET_STATUS';
export const SET_DREAM_WS_CONNECTION = 'SET_DREAM_WS_CONNECTION';
export const SET_SPORTS_RADAR_WS_CONNECTION = 'SET_SPORTS_RADAR_WS_CONNECTION';
export const SET_BETFAIR_WS_CONNECTION = 'SET_BETFAIR_WS_CONNECTION';
