import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import AcceptTermsModal from '../../components/AcceptTermsModal/AcceptTermsModal';
import ChangePwdForm from '../../components/ChangePassword/ChangePassword';
import './AcceptTerms.scss';

const AcceptTerms: React.FC = () => {
  const [openAcceptTerms, setOpenAcceptterms] = useState(false);
  const [changePwdModal, setChangePwdModal] = useState(true);
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Backdrop
        className="backdrop-ctn"
        open={openAcceptTerms || changePwdModal}
      >
        <Dialog
          open={openAcceptTerms}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle className="modal-title">User Details</DialogTitle>
          <DialogContent className="modal-content-ctn">
            <AcceptTermsModal
              closeHandler={() => {
                history.push('/');
                setOpenAcceptterms(false);
              }}
              successHandler={() => {
                setOpenAcceptterms(false);
                setChangePwdModal(true);
              }}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={changePwdModal}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle className="modal-title" id="responsive-dialog-title">
            Set your password
          </DialogTitle>

          <DialogContent className="modal-content-ctn">
            <ChangePwdForm
              showTermsCondi={true}
              closeHandler={() => {
                history.push('/');
                setChangePwdModal(false);
              }}
              backHandler={() => {
                setChangePwdModal(false);
                setOpenAcceptterms(true);
              }}
            />
          </DialogContent>
        </Dialog>
      </Backdrop>
    </>
  );
};

export default AcceptTerms;
