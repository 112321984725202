import { IndianCasinoState } from '../../models/IndianCasinoState';
import {
  UPDATE_CASINO_GAMES,
  SET_CASINO_GAME,
  UPDATE_GAME_DATA,
  UPDATE_GAME_RESULTS,
} from './CasinoActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initailState: IndianCasinoState = {
  casinoGames: [],
  selectedGmae: { id: '', name: '' },
  gameData: {
    gType: '',
    mId: '',
    roundDetails: [],
    roundCardDetails: [],
  },
  gameResults: [],
};

const indianCasinoReducer = (
  state = initailState,
  action: Action
): IndianCasinoState => {
  switch (action.type) {
    case UPDATE_CASINO_GAMES:
      return {
        ...state,
        casinoGames: action.payload,
      };

    case SET_CASINO_GAME:
      return {
        ...state,
        selectedGmae: action.payload,
        gameData: {
          gType: '',
          mId: '',
          roundDetails: [],
          roundCardDetails: [],
        },
      };

    case UPDATE_GAME_DATA: {
      const ogData = { ...state.gameData };
      const gData = {
        gType: action.payload.gType,
        mId: action.payload.mId !== '0' ? action.payload.mId : ogData.mId,
        roundDetails: action.payload.roundDetails,
        roundCardDetails: action.payload.roundCardDetails,
      };
      return {
        ...state,
        gameData: gData,
      };
    }

    case UPDATE_GAME_RESULTS: {
      return {
        ...state,
        gameResults: action.payload,
      };
    }

    default:
      return state;
  }
};

export default indianCasinoReducer;
